
.page-receive {
    .receive-tips {
        width: 92%;
        height: 74px;
        background: #ffffff;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        margin: 12px 15px;
        position: relative;
        .tips-blank {
            width: 68px;
            height: 20px;
            background: url(https://file.huatu.com/static/miniprogram/shop/h5/receive/icon-receive-tips01@2x.png)
                no-repeat 0 0;
            background-size: cover;
            position: absolute;
            top: 5px;
            left: 7px;
        }
        .tips-content {
            box-sizing: border-box;
            padding: 30px 15px 10px 15px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
        }
        .tips-icRb {
            width: 38px;
            height: 35px;
            background: url(https://file.huatu.com/static/miniprogram/shop/h5/receive/icon-receive-tips-halfcycle@2x.png)
                no-repeat 0 0;
            background-size: contain;
            position: absolute;
            bottom: 0px;
            right: 0px;
        }
    }
    .receive-container {
        background-color: #fff;
    }
    .tab-type {
        padding-bottom: 2px;
        .type {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .tit {
                font-size: 15px;
                font-weight: 600;
                color: #999999;
                line-height: 21px;
                padding: 10px;
                text-align: center;
            }
            .active {
                font-size: 18px;
                font-weight: 600;
                color: #333333;
                line-height: 25px;
                position: relative;
            }
            .active::before {
                content: "";
                position: absolute;
                left: 50%;
                bottom: 0;
                width: 24px;
                height: 9px;
                margin-left: -12px;
                background: url(../../assets/image/home/icon-lu.png);
                background-size: 24px 9px;
                background-repeat: no-repeat;
            }
            /deep/.van-tabs__nav--complete {
                padding-right: 0;
                padding-left: 0;
            }
        }
    }
}
