
.logisticsPage {
  height: 100%;
  background: #f8f9fa;
  .page-info {
    background: #ffffff;
    border-radius: 8px;
    margin: 12px 16px 0 16px;
    padding: 15px;
    .info-hd {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .hd-tit {
        width: 249px;
        text-align: start;
      }
      .hd-status {
        font-size: 14px;
        color: #9fa6af;
        line-height: 24px;
      }
      .status-01 {
        color: #fc6c21;
      }
      .status-02 {
        color: #ff2223;
      }
    }
    .hd-number {
      font-size: 12px;
      color: #999999;
      line-height: 17px;
      padding: 10px 0 13px 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: none;
      .icon-copy {
        display: block;
        width: 12px;
        height: 12px;
        margin-left: 5px;
        background: url(../../assets/icons/icon-copy.png) center/100% 100%
          no-repeat;
      }
    }
  }
  .page-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 8px 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    .lt {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .ft-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-left: 10px;
      .icon-kefu {
        display: block;
        width: 20px;
        height: 20px;
        background: url(https://file.huatu.com/static/miniprogram/shop/h5/detail/icon_kefu.png)
          center/100% 100% no-repeat;
      }
      .icon-wuliu {
        display: block;
        width: 20px;
        height: 20px;
        background: url(../../assets/icons/icon-wuliu.png) center/100% 100%
          no-repeat;
      }
      .txt {
        font-size: 11px;
        color: #333333;
        line-height: 16px;
      }
    }
  }
}
