
.page-refund {
	height: 100%;
	background: #F9F9F9;
    font-size: 14px;
	.page-item{
        margin: 12px 15px 0 15px;
        background: #ffffff;
        box-shadow: 0px 0px 9px 0px rgba(96, 105, 142, 0.06);
        border-radius: 8px;
        line-height: 16px;
        display: flex;
        justify-content: space-between;
        .item-name{
            width: 100px;
            font-size: 15px;
            font-weight: 500;
            color: #333333;
            line-height: 22px;
            padding: 18px 0 18px 12px;
        }
        .item-name-01{
            padding: 10px 0;
        }
        .item-rt{
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            /deep/ .van-cell {
                padding: 10px 0 10px 16px;
            }
            /deep/ .van-cell::after {
                border: none;
            }
            /deep/ .van-icon{
                margin: 0 15px 0 7px;
            }
            .ipt{
                text-align: end;
                background: none;
                height: 100%;
                flex: 1;
            }
        }
        /deep/.van-field__word-limit{
            color: #CCCCCC;
            font-size: 14px;
        }

    }
    .page-item-01{
        padding: 8px 0 0 12px;
    }
    .big-btn{
        position: fixed;
        left: 50%;
        bottom: 24px;
        margin-left: -167.5px;
    }
}
